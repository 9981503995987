<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0" cols="12" md="6">
        <v-card
          elevation="0"
          class="text-left white--text page activation-page-left"
        >
          <div style="padding-top: 30vh">
            <div style="height: 50px; width: 160px">
              <v-img
                style="height: 100%; width: 100%"
                :lazy-src="require('@/assets/logo-white.png')"
                :src="require('@/assets/logo-white.png')"
              ></v-img>
            </div>
            <h1 class="mt-2">Connecting Hearts, Empowering Teams!</h1>
            <p class="mt-3 sumo-font">
              Record and send personalized video messages that resonate with
              your audience
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col class="pa-0 ma-0" cols="12" md="6">
        <v-card elevation="0" class="text-left page" style="padding: 0 100px">
          <div style="padding-top: 10vh">
            <div style="height: 50px; width: 160px">
              <v-img
                class="ml-n2"
                style="height: 100%; width: 100%"
                :lazy-src="require('@/assets/logo.png')"
                :src="require('@/assets/logo.png')"
              ></v-img>
            </div>
            <h1 class="primary--text bold-text mt-2">Welcome Sumo-lings!</h1>
            <p class="sumo-font">Your Konvey account is almost ready!</p>
            <p class="sumo-font">{{ $route.query.activation_email }}</p>
            <v-form ref="form">
              <v-row class="mt-5">
                <label class="black-text pl-3">Password</label>
                <v-col cols="12" class="my-0 py-0">
                  <v-text-field
                    class="my-0 py-0"
                    solo
                    flat
                    dense
                    outlined
                    v-model="form.password"
                    :type="!showPassword ? 'password' : 'text'"
                    :rules="validate.required('password')"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="showPassword = !showPassword"
                        color="grey"
                        class="pointer"
                      >
                        {{
                          !showPassword
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                  <label class="black-text">First Name</label>
                  <v-text-field
                    class="my-0 py-0"
                    solo
                    flat
                    dense
                    outlined
                    v-model="form.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                  <label class="black-text">Last Name</label>
                  <v-text-field
                    class="my-0 py-0"
                    solo
                    flat
                    dense
                    outlined
                    v-model="form.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <div class="mt-n5 d-flex">
                    <v-checkbox
                      class="my-0 py-0 mt-2"
                      color="primary"
                      :true-value="true"
                      :false-value="false"
                      v-model="agreeToTerms"
                    >
                    </v-checkbox>
                    <div class="mt-n2">
                      <div
                        class="mt-4"
                        style="width: 57%; font-size: 13px; z-index: 999999"
                      >
                        By joining Konvey, you agree to our
                        <a
                          class="link bold-text"
                          href="https://konvey.app/terms-and-conditions"
                          target="_blank"
                        >
                          Terms of service
                        </a>
                        and
                        <a
                          class="link bold-text"
                          href="https://konvey.app/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="mt-3">
                  <v-btn
                    block
                    large
                    class="primary bold-text"
                    :disabled="!agreeToTerms"
                    :loading="loading"
                    @click="$refs.form.validate() ? signUp() : null"
                  >
                    Activate
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions } from "vuex";
export default {
  name: "AppSumoRegister",
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
      agreeToTerms: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sign_up"]),
    async signUp() {
      this.loading = true;
      this.form.email = this.$route.query.activation_email;
      this.form.name = `${this.form.first_name} ${this.form.last_name}`;
      if (this.$route.query.partner) {
        this.form.partner_refId = this.$route.query.partner;
      }
      try {
        const response = await this.sign_up(this.form);
        this.toast.sendSuccess(response);
        this.loading = false;
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
  },
};
</script>

<style scoped>
body {
  margin: 0 !important;
  padding: 0 !important;
}

.bold-text {
  font-weight: 900;
}

.page {
  height: 100vh;
  width: 100%;
  border-radius: 0 !important;
}

.activation-page-left {
  background: linear-gradient(to right, #6257fe, #3ca0ec);
  margin: 0;
  padding: 0 100px;
}

.sumo-font {
  font-size: 20px;
  font-weight: 400;
}

.link {
  text-decoration: none;
}
</style>
