<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center" style="margin-top: 7vh">
      <v-col cols="12" md="4">
        <div class="center-logo">
          <app-logo></app-logo>
        </div>
        <v-card flat class="rounded-xl">
          <v-toolbar flat class="d-flex justify-center">
            <v-card-title class="bold-text">Sign Up</v-card-title>
          </v-toolbar>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <label class="pl-4">Email</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="secondary"
              :rules="validate.required('email')"
              placeholder="Email"
              type="email"
              v-model="form.email"
            >
              <template v-slot:prepend-inner>
                <v-icon class="pa-2 ml-n6 rounded-xl primary" color="white">
                  mdi-email-outline
                </v-icon>
              </template>
            </v-text-field>
            <label class="pl-4">Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              :rules="validate.required('password')"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form.password"
            >
              <template v-slot:prepend-inner>
                <v-icon color="white" class="primary ml-n6 pa-2 rounded-xl">
                  mdi-lock-outline
                </v-icon>
              </template>
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <div class="mt-n5 d-flex justify-lg-space-between">
              <v-checkbox color="primary">
                <template v-slot:label>
                  <small>
                    I agree to Terms of
                    <router-link to="/terms-of-service">
                      Terms of Service
                    </router-link>
                    and
                    <router-link to="/privacy-policy">
                      Privacy Policy
                    </router-link>
                  </small>
                </template>
              </v-checkbox>
            </div>
            <div class="justify-center d-flex pb-4">
              <v-btn
                text
                class="rounded-xl px-7 py-5 primary capitalize"
                @click="$refs.form.validate() ? signUp() : null"
                :loading="loading"
                >sign up</v-btn
              >
            </div>
            <v-row class="secondary rounded-lg mt-2">
              <v-card-text class="text-center pa-4">
                Got an account already?
                <router-link to="/sign_in">Sign In</router-link>
              </v-card-text>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions } from "vuex";
export default {
  name: "RegisterComponent",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sign_up"]),
    async signUp() {
      this.loading = true;
      if (this.$route.query.partner) {
        this.form.partner_refId = this.$route.query.partner;
      }
      try {
        const response = await this.sign_up(this.form);
        this.toast.sendSuccess(response);
        this.loading = false;
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
  },
};
</script>

<style scoped>
.center-logo {
  display: inline-flex;
}
.bold-text {
  font-weight: 900;
}
</style>
